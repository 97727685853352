import React from 'react';
import { getResizedImageUrl } from '@lib/image';
import { v4 as uuidv4 } from 'uuid';

interface IResponsiveimageSizes {
  media: string;
  width: number;
  height: number;
}

interface IResponsiveimage {
  url: string;
  alt: string;
  className: string;
  sizes: IResponsiveimageSizes[];
  width: number;
  height: number;
  format: string;
}

/**
 * Returns a responsive image
 * Example:
 * <ResponsiveImage
 *  url={'https://static.havenly.com/home/hero/odette.jpg'}
 *  alt={'Title'}
 *  sizes={[
 *  {
 *  media: '(min-width: 1440px)',
 *  width: 1440,
 *  height: 841
 *  },
 *  {
 *  media: '(min-width: 1100px)',
 *  width: 1100,
 *  height: 841
 *  },
 *  ]}
 *  width={375}
 *  height={287}
 *  format={'jpeg'}
 *  />
 * @param url
 * @param alt
 * @param className
 * @param sizes
 * @param width
 * @param height
 * @param format
 * @constructor
 */
export default function ResponsiveImage({
  url,
  alt,
  className = '',
  sizes,
  width,
  height,
  format,
}: IResponsiveimage) {
  const imageUrl = url;
  const imageSource = getResponsiveImages(imageUrl, width, height, format);

  return (
    <picture>
      {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
      {['webp', 'jpeg'].map((format) => (
        <React.Fragment key={uuidv4().slice(0, 5)}>
          {[sizes].map((size) => {
            if (!size) return null;
            return size.map((item) => (
              <source
                key={uuidv4().slice(0, 6)}
                srcSet={getResponsiveImages(
                  imageUrl,
                  item.width,
                  item.height,
                  format
                )}
                media={item.media}
              />
            ));
          })}
          <source
            srcSet={getResponsiveImages(imageUrl, width, height, format)}
          />
        </React.Fragment>
      ))}
      <img
        loading="lazy"
        src={imageSource}
        alt={alt}
        className={className}
      />
    </picture>
  );
}

function getResponsiveImages(
  image: string | undefined,
  width: number,
  height: number,
  format: string
) {
  if (!image) return '';
  const main = [`${getThumborURL(image, width, height, format)} ${width}w`];
  main.push(`${getThumborURL(image, width * 2, height * 2, format)} 2x`);

  return main.join(', ');
}

function getThumborURL(
  url: string | undefined,
  width: number,
  height: number,
  format: string
): string {
  if (!url) return '';
  return getResizedImageUrl({
    format,
    quality: 90,
    url,
    width,
    height,
  });
}
