import React from 'react';
import ComparisonSlider from '@components/shared/ComparisonSlider/ComparisonSlider';
import themeStyles from '@components/theme/default/styles.module.scss';
import ResponsiveImage from '@components/shared/Image/ResponsiveImage/ResponsiveImage';
import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import classNames from 'classnames';
import comparisonData from './comparisonData.json';
import styles from './BeforeAndAfter.module.scss';

const BeforeAndAfter = () => {
  const RenderSlider = (items: any) => {
    const slider = {
      itemOne: {
        content: items.itemOne,
        description: items.itemOneA11yText
      },
      itemTwo: {
        content: items.itemTwo,
        description: items.itemTwoA11yText
      },
      initialValue: '30',
      inputLabel: 'See before and after room redesign'
    };

    return <ComparisonSlider {...slider} />;
  };

  const secondLevelTabs = (item: any) => {
    return item.map((el: any) => {
      return (
        <Tab key={el.id} className={`react-tabs__tab ${styles.secondLevelTab}`}>
          <span className={styles.secondLevelTabLabel} data-text={el.project}>{el.project}</span>
        </Tab>
      );
    });
  };

  const secondLevelTabPanels = (item: any) => {
    return item.map((el: any) => {
      return (
        <TabPanel key={el.id} className={`react-tabs__tab-panel ${styles.secondLevelTabPanel}`}>
          <div className={styles.slideWrapper}>
            <RenderSlider
              itemOne={(
                <div className={styles.slideImage}>
                  <ResponsiveImage
                    url={el.imgLeft}
                    alt={el.imgLeftAlt}
                    className=""
                    sizes={[{
                      media: '(min-width: 1400px)',
                      width: 1334,
                      height: 750,
                    }, {
                      media: '(min-width: 1100px)',
                      width: 1250,
                      height: 841,
                    }, {
                      media: '(min-width: 600px)',
                      width: 1009,
                      height: 841,
                    }]}
                    width={349}
                    height={218}
                    format={'jpeg'}
                  />
                </div>
              )}
              itemOneA11yText={el.imgLeftAlt}
              itemTwo={(
                <div className={styles.slideImage}>
                  <ResponsiveImage
                    url={el.imgRight}
                    alt={el.imgRightAlt}
                    className=""
                    sizes={[{
                      media: '(min-width: 1400px)',
                      width: 1334,
                      height: 750,
                    }, {
                      media: '(min-width: 1100px)',
                      width: 1250,
                      height: 841,
                    }, {
                      media: '(min-width: 600px)',
                      width: 1009,
                      height: 841,
                    }]}
                    width={349}
                    height={218}
                    format={'jpeg'}
                  />
                </div>
              )}
              itemTwoA11yText={el.imgRightAlt}
            />
          </div>
        </TabPanel>
      );
    });
  };

  const topLevelTabs = comparisonData.items.map((item) => {
    return (
      <Tab key={item.id} className={`react-tabs__tab ${styles.topLevelTab}`}>
        <img src={item.icon} alt={''} className={styles.tabIcon} />
        {item.room}
      </Tab>
    );
  });

  const topLevelTabPanels = comparisonData.items.map((item) => {
    return (
      <TabPanel key={item.id}>
        <Tabs forceRenderTabPanel>
          <TabList className={`react-tabs__tab-list ${styles.secondLevelTabs}`}>
            {secondLevelTabs(item.projects)}
          </TabList>
          {secondLevelTabPanels(item.projects)}
        </Tabs>
      </TabPanel>
    );
  });

  const allTabs = (
    <Tabs forceRenderTabPanel defaultIndex={0}>
      <TabList className={`react-tabs__tab-list ${styles.topLevelTabs}`}>
        {topLevelTabs}
        <a role="tab" href={'/exp/interior-design-ideas'} className={styles.topLevelTab}>
          <img className={styles.tabIcon} src={'https://static.havenly.com/home/before-and-after/more.svg'} alt={''} />
          Explore More
        </a>
      </TabList>
      {topLevelTabPanels}
    </Tabs>
  );

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.h2}>
          What’s the best that could happen?
          <span>Explore real client before & afters.</span>
        </h2>
        <div className={styles.tabsWrapper}>
          {allTabs}
        </div>
        <a
          href={'/interior-design-style-quiz/style-inspiration'}
          className={classNames(themeStyles.Button,
            themeStyles.Primary,
            themeStyles.Medium,
            styles.CTAButton)}
        >
          Get Started
        </a>
      </div>
    </section>
  );
};

export default BeforeAndAfter;
